@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-Regular'), url(./assets/fonts/Ubuntu-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-Italic'), url(./assets/fonts/Ubuntu-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-Light'), url(./assets/fonts/Ubuntu-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-LightItalic'), url(./assets/fonts/Ubuntu-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-Medium'), url(./assets/fonts/Ubuntu-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-MediumItalic'), url(./assets/fonts/Ubuntu-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-Bold'), url(./assets/fonts/Ubuntu-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu-BoldItalic'), url(./assets/fonts/Ubuntu-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}
